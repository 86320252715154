import moment from "moment-timezone";
import { CustomFilterCategory } from "../components/shared/filters/filter.model";
import { mapToTimeZoneModel, TimeZoneModel } from "../models/settings-model";
import { SettingsService } from "./SettingsService";
import { UserService } from "./UserService";

const dateFormat = (dateTime: any) => {
	return new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric' })
		.format(new Date(dateTime));
}

const CompareStrings = (value1: any, value2: any) => {
	return !!value1 && !!value2 && value1.toString().toLowerCase().indexOf(value2.toString().toLowerCase()) != -1
}

const isValidEmail = (email: any) => {
	if (!!email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"){1,64})@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,255}))$/;
		return re.test(String(email).toLowerCase());
	}
	return true;
};

const validateEmailLength = (email: string) => {
	const parts = email.split('@');

	if (parts[0].length > 64) {
		return 'Email address should be less than 64 characters!'
	}
	if (parts[1].length > 255) {
		return 'Invalid Email!'
	}
}

const validPhoneNumber = (phoneNumber: any) => {
	if (!!phoneNumber) {
		var re = /^\d{11,15}$/;
		return re.test(unmaskPhoneNumber(phoneNumber));
	}
	return false;
};
const isPhoneNumberValidWithRestrictedRange = (phoneNumber) => {
	if (!!phoneNumber) {
		// 11 min value & 15 max value
		var re = /^\d{11,15}$/;
		return re.test(phoneNumber);
	}
	return false;
}

const validPassword = (password: any) => {
	if (!!password) {
		var re = /^(((?=.*[A-Za-z])(?=.*\d)|(?=.*[@$!%*#?&`])[A-Za-z\d@$!%*#?&`]).{8,20})$/;
		return re.test(password);
	}
	return true;
}

const validatePassword = (password: any) => {
	if (!!password) {
		var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!~#@$%^&*`)(+=._-])[A-Za-z\d!~#@$%^&*`)(+=._-]{8,20}$/;
		return re.test(password);
	}
	return true;
}

const getDaysDifference = (start: any, end: any) => {
	let startDate = new Date(start);
	let endDate = new Date(end);

	var difference_In_Time = endDate.getTime() - startDate.getTime();
	return difference_In_Time / (1000 * 3600 * 24);
};

const convertToDay = (dateTime: any) => {
	let tZone = SettingsService.getUserPreferredTimeZone();
	return new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit' })
		.format(new Date(tZone ? new Date(dateTime).toLocaleString("en-US", { timeZone: SettingsService.getUserPreferredTimeZone() }) : dateTime));
};

const convertDateToDay = (dateTime: any) => {
	let tZone = SettingsService.getUserPreferredTimeZone();
	return new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric' })
		.format(new Date(tZone ? new Date(dateTime).toLocaleString("en-US", { timeZone: SettingsService.getUserPreferredTimeZone() }) : dateTime));
};

const convertToDate = (dateTime: any) => {
	let tZone = SettingsService.getUserPreferredTimeZone();
	return new Intl.DateTimeFormat('en-US')
		.format(new Date(tZone ? new Date(dateTime).toLocaleString("en-US", { timeZone: tZone }) : dateTime));
};

const convertToDateFormat = (dateTime: any) => {
	let tZone = SettingsService.getUserPreferredTimeZone();
	return new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
		.format(new Date(tZone ? new Date(dateTime).toLocaleString("en-US", { timeZone: tZone }) : dateTime));
};

const convertToDateWithWeekFormat = (dateTime: any) => {
	let tZone = SettingsService.getUserPreferredTimeZone();
	return new Intl.DateTimeFormat('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
		.format(new Date(tZone ? new Date(dateTime).toLocaleString("en-US", { timeZone: tZone }) : dateTime));
};

const convertToTimeFormat = (dateTime: any) => {
	let tZone = SettingsService.getUserPreferredTimeZone();
	return new Intl.DateTimeFormat('en-US', { hour: "2-digit", minute: "2-digit" })
		.format(new Date(tZone ? new Date(dateTime).toLocaleString("en-US", { timeZone: tZone }) : dateTime));
};
const convertToDateTimeFormat = (dateTime: any) => {
	let tZone = SettingsService.getUserPreferredTimeZone();

	return new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: "2-digit", minute: "2-digit" })
		.format(new Date(tZone ? new Date(dateTime).toLocaleString("en-US", { timeZone: tZone }) : dateTime));
};

const getPreferredTimeZoneTimeNow = () => {
	let tZone = SettingsService.getUserPreferredTimeZone();
	return tZone ? new Date(new Date().toLocaleString("en-US", { timeZone: tZone })) : new Date();
}

const convertToPreferredTimeZone = (dateTime: any) => {
	let tZone = SettingsService.getUserPreferredTimeZone();
	return tZone ? new Date(new Date(dateTime).toLocaleString("en-US", { timeZone: tZone })) : new Date(dateTime);
}

const convertToLocalTimeZone = (dateTime: any) => {
	let tZone = getPreferedTimeZone();
	return new Date(`${moment(dateTime).format(`MM-DD-YYYY HH:mm`)} ${tZone?.OffsetLabel}`);
}

const sort = (a: any, b: any, orderBy: any) => {
	var v1 = a[orderBy] || "";
	var v2 = b[orderBy] || "";

	if (v2 < v1) {
		return -1;
	}
	if (v2 > v1) {
		return 1;
	}
	return 0;
};

const getComparator = (order: any, orderBy: any) => {
	return order === 'desc'
		? (a: any, b: any) => sort(a, b, orderBy)
		: (a: any, b: any) => -sort(a, b, orderBy);
};

const sortArray = (array: any, comparator: any) => {
	const stabilizedThis = array.map((el: any, index: any) => [el, index]);
	stabilizedThis.sort((a: any, b: any) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el: any) => el[0]);
};


const displayTime = (messageDateTime: any) => {
	var now = convertToDate(new Date());
	var mDate = convertToDate(messageDateTime);

	let stateDate = convertToPreferredTimeZone(messageDateTime);
	let endDate = getPreferredTimeZoneTimeNow()
	var days = getDaysDifference(stateDate, endDate);
	if (now === mDate) {
		return convertToTimeFormat(messageDateTime);
	}
	else if (days <= 7) {
		return days <= 2 && endDate.getDate() - stateDate.getDate() === 1 ? "Yesterday" : convertToDateWithWeekFormat(messageDateTime).split(",")[0];
	}
	else {
		return mDate;
	}

};


const maskPhoneNumber = (number: any, escape = ' ') => {

	let i = 0;
	let numberText = unmaskPhoneNumber(number || "").toString().replace("+", '');
	let pattern = '+# (###) ###-####';

	return numberText.length ? pattern.slice(0, pattern.split("#", numberText.length).join('#').length + 1).replace(/#/g, x => numberText[i++] || escape) : '';
}

const unmaskPhoneNumber = (maskedPhonenumber: string) => {
	let result = maskedPhonenumber?.replace(/[^a-zA-Z0-9]/g, "")?.match(/^\d{0,15}/);

	return result ? result[0] : "";
}

const unmaskPhoneNumberWithPlus = (maskedPhonenumber: string) => {
	let result = unmaskPhoneNumber(maskedPhonenumber);

	return result ? `+${result}` : "";
}

const maskEmail = (email: string) => {
	if (!isValidEmail(email)) {
		return email;
	}
	let emailParts = email.split("@");

	return `${emailParts[0][0]}**********${emailParts[0][emailParts[0].length - 1]}@${emailParts[1]}`
}

const getInitials = (firstName: string, lastName: string) => {
	return `${firstName?.trim()[0] || "U"} ${lastName?.trim()[0] || (firstName?.trim()?.split(" ")[1] ? firstName?.trim()?.split(" ")[1][0] : firstName?.trim()[1]) || (firstName?.trim()[0] ? "" : "K")}`;
}

const getGroupChatInitials = (firstName: string, lastName: string) => {
	if (firstName.length === 1 && !lastName) {
		return firstName + " " + firstName
	}
	else
		return `${firstName?.trim()[0] || "U"} ${lastName?.trim()[0] || (firstName?.trim()?.split(" ")[1] ? firstName?.trim()?.split(" ")[1][0] : firstName?.trim()[1]) || (firstName?.trim()[0] ? "" : "K")}`;
}

const convertUrlToBlob = (MediaUrl: string) => {
	return fetch(MediaUrl).then(res => res.blob()).then(blob => {
		return URL.createObjectURL(blob);
	})
}


const getColorCode = (text: string) => {
	let hash = (text.split("").reduce((p, c) => c.charCodeAt(0) * p, 1) % 0xFFFFFF);
	let hexaHash = hash.toString(16).toUpperCase();
	let colorCode = "#000000".substring(0, 7 - hexaHash.length) + hexaHash;

	return colorCode;
}

const getHashIndex = (text: string, cutoff: number) => {
	let hash = (text.split("").reduce((p, c) => c.charCodeAt(0) * p, 1) % cutoff);

	return hash % cutoff;
}

const maskSingleDigitWithZero = (number: number): string => {
	return parseFloat(number.toString()).toLocaleString('en-US', {
		minimumIntegerDigits: 2,
		useGrouping: false
	})
}

const getTimeZones = (): (TimeZoneModel | undefined)[] => {
	let list = moment.tz.names().map(x => mapToTimeZoneModel(x)).sort((a: any, b: any) => a.Offset < b.Offset ? 1 : -1);
	return list.filter((c: any, index) => list.findIndex((d: any) => d.Name === c.Name) === index);
}

const getPreferedTimeZone = (): TimeZoneModel | undefined => {
	let tZone = SettingsService.getUserPreferredTimeZone();

	return tZone ? mapToTimeZoneModel(tZone) : getDefaultTimeZone();
}

const getDefaultTimeZone = (): TimeZoneModel | undefined => {
	return mapToTimeZoneModel(moment.tz.guess(true));
}

const roundTimeToMinutes = (time: number, minutes: number): number => {
	let roundedTime = new Date(time).getTime();
	if (new Date(time).getMinutes() % minutes) {
		let ticks = (minutes * 60 * 1000);
		roundedTime = roundedTime - (roundedTime % ticks) + ticks;
	}
	return roundedTime;
}

const isValidURL = (link: any) => {
	if (!!link) {
		var re = new RegExp(/^(ftp|http|https|chrome|ww.|WW.|:\/\/|\.|@){2,}(localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\S*:\w*@)*([a-zA-Z]|(\d{1,3}|\.){7}){1,}(\w|\.{2,}|\.[a-zA-Z]{2,3}|\/|\?|&|:\d|@|=|\/|\(.*\)|#|-|%)*$/gm)
		return re.test(String(link));
	}
	return false;
};


const maskPhoneNumberOnLength = (number: any, escape = ' ') => {
	let i = 0;
	let numberText = unmaskPhoneNumber(number || "").toString().replace("+", '');
	let pattern = '';
	if (numberText?.trim().length === 11) {
		pattern = '+# (###) ###-####';
		return numberText?.length ? pattern.slice(0, pattern.split("#", numberText.length).join('#').length + 1).replace(/#/g, x => numberText[i++] || escape) : '';
	}
	else {
		let numberWithOutPlus = number?.replace("+", "");
		pattern = `+${'#'.repeat(numberWithOutPlus?.length)}`;
		return numberText.length ? pattern.slice(0, pattern.split("#", numberWithOutPlus?.length).join('#').length + 1).replace(/#/g, x => numberWithOutPlus[i++] || escape) : '';
	}
}

const unmaskPhoneNumberOnLength = (maskedPhonenumber: string) => {
	let result = maskedPhonenumber.replace(/[^\d]/g, "");

	return result
}

const trimMessage = (message: string, chars: number) => {
	return !!message ? message.slice(0, chars) + (message.length > chars ? "..." : "") : "";
}

const breakString = (text: string, lenght: number): string => {
	return text.match(new RegExp(`.{1,${lenght}}`, 'g'))?.join('\n') ?? '';
}

const containsWhitespace = (str: string) => {
	return /\s/.test(str);
}

const isTabDevice = (details: string) => {
	let regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
	let isSmallDevice = regexp.test(details);
	return isSmallDevice
}

const isIPhone = (details: string) => {
	let regexp = /iPhone/;
	let isIPhone = regexp.test(details);
	return isIPhone
}

const isAndroid = (details: string) => {
	let regexp = /android/i;
	let isAndroid = regexp.test(details);
	return isAndroid
}


const getFilterTypes = (filters: any) => {
	const result = filters.reduce((types: any, category: any) => {
		const selectedOptions = category.Options.filter((option: any) => option.IsSelected);
		if (selectedOptions.length > 0) {
			types.push(category.Title);
		}
		return types;
	}, []);

	return { result };
}

const truncateNumbers = (numbers: any) => {
	let truncated = numbers.slice(0, 2);
	truncated = truncated.map((x: any) => maskPhoneNumberOnLength(x)).join(", ")
	truncated = truncated.length > 20 ? trimMessage(truncated, 20) : truncated
	return truncated
}


//************filters***************/
const getValueFromNestedPath = (object: any, path: any) => {
	const pathArr = Array.isArray(path) ? path : path.split(".");
	let value = object;
	// console.log("value:", value)
	// console.log("pathArr:", pathArr)
	for (const key of pathArr) {
		if (Array.isArray(value)) {
			value = value.map((item) => item[key]);
			// console.log("value:", value)
		} else if (value !== null && typeof value === "object") {
			// console.log("key:", key)
			// console.log("value:", value)
			value = value[key];
		} else {
			value = undefined;
			break;
		}
	}
	return value;
}

const applyFilters = (data: any, filters: any): { filteredData: any[]; selectedFilters: number; selectedFilterTypes: string[] } => {
	const selectedFilters: CustomFilterCategory[] = filters.filter((filter: any) =>
		filter.Options.some((option: any) => option.IsSelected && option.Key !== "")
	);

	if (selectedFilters.length === 0) {
		return { filteredData: [], selectedFilters: 0, selectedFilterTypes: [] };
	}

	const filteredData = data.filter((x: any) => {
		for (const filter of selectedFilters) {
			const selectedOptions = filter.Options
				.filter((option) => option.IsSelected && option.Key !== "")
				.map((option) => option.Key);

			if (selectedOptions.length > 0) {
				// console.log("filterValue:", filterValue)
				// console.log("selectedOptions:", selectedOptions)
				const filterValue = getValueFromNestedPath(x, filter.Title);

				if (Array.isArray(filterValue)) {
					if (!filterValue.some((value) => selectedOptions.includes(value))) {
						return false;
					}
				} else if (!selectedOptions.includes(filterValue)) {
					return false;
				}
			}
		}
		return true;
	});

	const selectedFilterTypes = selectedFilters.map((filter) => filter.Title);

	return {
		filteredData,
		selectedFilters: selectedFilters?.map(filter => filter?.Options.filter(option => option?.IsSelected).length).reduce((sum, count) => sum + count, 0),
		selectedFilterTypes,
	};

}

const getFilteredPhoneNumbers = (PhoneNumbers) => {
	return PhoneNumbers.filter(y =>
		y.PhoneNumber !== UserService.getUserPhoneNumber() &&
		(!UserService.hasSysAdminRole() || (y.OrganizationID === y.OrganizationID && !!y.Provider && !y.IsDeleted))
	);
};

const copyTextFromTeams = (text) => {
	const newElement = document.createElement("div");
	newElement.textContent = text;
	document.body.appendChild(newElement);
	const range = document.createRange();
	range.selectNode(newElement);
	const selection = window.getSelection();
	selection.removeAllRanges();
	selection.addRange(range);
	document.execCommand("copy");
	document.body.removeChild(newElement);
}


export const UtilityService = {
	isValidEmail,
	convertToDateFormat,
	convertToTimeFormat,
	convertToDateWithWeekFormat,
	convertToDate,
	getComparator,
	sortArray,
	getDaysDifference,
	validPhoneNumber,
	CompareStrings,
	convertToDay,
	validPassword,
	displayTime,
	dateFormat,
	validatePassword,
	maskPhoneNumber,
	convertDateToDay,
	unmaskPhoneNumber,
	unmaskPhoneNumberWithPlus,
	convertToDateTimeFormat,
	maskEmail,
	getInitials,
	getGroupChatInitials,
	getColorCode,
	getHashIndex,
	maskSingleDigitWithZero,
	getTimeZones,
	getDefaultTimeZone,
	getPreferredTimeZoneTimeNow,
	convertToPreferredTimeZone,
	getPreferedTimeZone,
	convertToLocalTimeZone,
	roundTimeToMinutes,
	isValidURL,
	unmaskPhoneNumberOnLength,
	maskPhoneNumberOnLength,
	trimMessage,
	breakString,
	containsWhitespace,
	isTabDevice,
	isIPhone,
	isAndroid,
	applyFilters,
	validateEmailLength,
	getFilterTypes,
	truncateNumbers,
	convertUrlToBlob,
	isPhoneNumberValidWithRestrictedRange,
	getFilteredPhoneNumbers,
	copyTextFromTeams
};

//************filters***************/

