import moment from "moment-timezone";
import { Contact } from "./conversations";

export class SettingsModel {
    constructor() {
    }
    BlockedContacts: Contact[];
    Settings: Setting[];
    NotificationsEnabled: boolean;
    TwoFactAuthEnabled: boolean;
    VoiceVerificationEnabled: boolean;
    BlockIncomingMMSEnabled: boolean;
    BlockIncomingMessageLinksEnabled: boolean;
    AllowedWrongPasswordAttempts: number;
    UnSubscribedContacts: Contact[];
}

export class Setting {
    constructor() { }
    ID?: string;
    Name?: string;
    Code?: string;
    Value?: number;
}

export const mapToSetting = (x: any): Setting => {
    return {
        ID: x.id,
        Name: x.name,
        Code: x.code,
        Value: x.value
    } as Setting;
}

export class TimeZoneModel {
    Name: string;
    Zone: string;
    OffsetLabel: string;
    Offset: number;
};

export const mapToTimeZoneModel = (name: string) => {
    if (!name) {
        return undefined;
    }
    let time = new Date().getTime();
    return {
        Name: name,
        Offset: moment.tz.zone(name)?.utcOffset(time),
        OffsetLabel: `UTC ${moment.tz(name).format('Z')}`,
        Zone: moment.tz.zone(name)?.abbr(time)
    } as TimeZoneModel;
}
export interface BusinessHoursModel {
    DAY: string;
    OPEN_TIME: number;
    CLOSE_TIME: number;
    SELECTED: boolean;
}

export const DaysInAWeek = {
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    SUNDAY: "Sunday"
}

export interface WorkHours {
    DayOfTheWeek: string;
    FromHour: string;
    ToHour: string;
}

export class ComplianceMsgModel {
    OrgReference: string;
    PhoneNumber: string;
    OptInMessage: string;
    OptOutMessage: string;
    HelpMessage: string;
    IsOptInMessageEnabled: boolean;
}

export const mapToComplianceMsgsettings = (x: any): ComplianceMsgModel => {
    return {
        OrgReference: x.orgReference,
        PhoneNumber: x.phoneNumber,
        OptInMessage: x.optInMessage,
        OptOutMessage: x.optOutMessage,
        HelpMessage: x.helpMessage,
        IsOptInMessageEnabled: x.isOptInMessageEnabled,
    } as ComplianceMsgModel;
}
export class LowCreditSettingsModel {
    constructor() { }
    GroupCredits: number
    NormalCredits: number
    EmailAlertEnabled: boolean
}
export const mapToLowCreditSettingsModel = (x: any): LowCreditSettingsModel => {
    return {
        GroupCredits: x.groupCredits,
        NormalCredits: x.normalCredits,
        EmailAlertEnabled: x.emailAlertEnabled
    } as LowCreditSettingsModel
}